import { createContext, useContext } from 'react';
import createPersistedReducer from 'use-persisted-reducer';

const usePersistedReducer = createPersistedReducer('stateDashboard');

export const DashboardContext = createContext();

export function useDashboardContext(){
    return useContext(DashboardContext);
}

function reducer(state,action){
    switch (action.type) {
        case 'updateTitlePage':
            return { ...state, titlePage: action.payload };
        case 'updateUserData':
            return { ...state, userData: action.payload };
        case 'updateJatuhTempo':
            return { ...state, jatuhTempo: action.payload };
        default:
            throw new Error('unexpected action')
    }
}

const initialState = {
    titlePage:"",
    userData:{}
}

export function DashboardProvider({children}){
    const [state,dispatch]=usePersistedReducer(reducer,initialState);

    const dashboardContextValue = [state,dispatch];

    return (
        <DashboardContext.Provider value={dashboardContextValue}>
            {children}
        </DashboardContext.Provider>
    )
}