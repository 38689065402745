import * as React from "react";
import {createRoot} from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import './index.css'
import HomeApp from "./App";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    {/* No basename for this router. This app renders at the root / URL. */}
    <BrowserRouter>
      <HomeApp />
    </BrowserRouter>
  </React.StrictMode>
);
