import React, { Suspense } from "react";
import "./App.scss";
import { Route, Routes, Outlet } from "react-router-dom";
import Loading from './views/pages/Loading/Loading.jsx';
import { DashboardProvider } from "./context/DashboardContextNew";

// Dahboard Page
const Dashboard = React.lazy(() => import("./views/pages/Dashboard/Dashboard"));
const Customer = React.lazy(() => import("./views/pages/Dashboard/Customer/Customer"));
const JenisPembayaran = React.lazy(() => import("./views/pages/Dashboard/JenisPembayaran/JenisPembayaran"));
const Rekanan = React.lazy(() => import("./views/pages/Dashboard/Rekanan/Rekanan"));
const Satuan = React.lazy(() => import("./views/pages/Dashboard/Satuan/Satuan"));
const Supplier = React.lazy(() => import("./views/pages/Dashboard/Supplier/Supplier"));
const NotaPenjualan = React.lazy(() => import("./views/pages/Dashboard/NotaPenjualan/NotaPenjualan"));
const NotaBelumLunas = React.lazy(() => import("./views/pages/Dashboard/NotaBelumLunas/NotaBelumLunas"));
const Bank = React.lazy(() => import("./views/pages/Dashboard/Bank/Bank.jsx"));
const Barang = React.lazy(() => import("./views/pages/Dashboard/Barang/Barang.jsx"));
const EksporBarang = React.lazy(() => import("./views/pages/Dashboard/Barang/EksporBarang.jsx"));
const BarangJadi = React.lazy(() => import("./views/pages/Dashboard/BarangJadi/BarangJadi.jsx"));
const BahanAlatProduksi = React.lazy(() => import("./views/pages/Dashboard/BahanAlatProduksi/BahanAlatProduksi.jsx"));
const SuratJalan = React.lazy(() => import("./views/pages/Dashboard/SuratJalan/SuratJalan.jsx"));
const SuratTagihan = React.lazy(() => import("./views/pages/Dashboard/SuratTagihan/SuratTagihan.jsx"));
const PemakaianBahanAlat = React.lazy(() => import("./views/pages/Dashboard/PemakaianBahanAlat/PemakaianBahanAlat.jsx"));
const PembayaranCustomer = React.lazy(() => import("./views/pages/Dashboard/PembayaranCustomer/PembayaranCustomer.jsx"));
const HasilProduksi = React.lazy(() => import("./views/pages/Dashboard/HasilProduksi/HasilProduksi.jsx"));
const BahanAlatProduksiMaster = React.lazy(() => import("./views/pages/Dashboard/BahanAlatProduksi/BahanAlatProduksiMaster.jsx"));
const Merk = React.lazy(() => import("./views/pages/Dashboard/Merk/Merk.jsx"));
const Operator = React.lazy(() => import("./views/pages/Dashboard/Operator/Operator.jsx"));
const JenisBarang = React.lazy(() => import("./views/pages/Dashboard/JenisBarang/JenisBarang.jsx"));
const BarangReject = React.lazy(() => import("./views/pages/Dashboard/BarangReject/BarangReject.jsx"));
const ReturBarang = React.lazy(() => import("./views/pages/Dashboard/ReturBarang/ReturBarang.jsx"));
const StokOpname = React.lazy(() => import("./views/pages/Dashboard/StokOpname/StokOpname_new.jsx"));
const LaporanPenjualan = React.lazy(()=> import("./views/pages/Dashboard/Laporan/Penjualan/Penjualan.jsx"));
const LaporanPembelianBarangJadi = React.lazy(()=> import("./views/pages/Dashboard/Laporan/Pembelian/PembelianBarangJadi.jsx"));
const LaporanPembelianBahanAlat = React.lazy(()=> import("./views/pages/Dashboard/Laporan/Pembelian/PembelianBahanAlat.jsx"));
const LaporanPiutang = React.lazy(()=> import("./views/pages/Dashboard/Laporan/Piutang/Piutang.jsx"));
const LaporanHasilProduksi = React.lazy(()=> import("./views/pages/Dashboard/Laporan/HasilProduksi/HasilProduksi.jsx"));
const LaporanPemakaianBahanAlat = React.lazy(()=> import("./views/pages/Dashboard/Laporan/PemakaianBahanAlat/PemakaianBahanAlat.jsx"));
const LaporanMutasiStokBarangJadi = React.lazy(()=> import("./views/pages/Dashboard/Laporan/MutasiStok/MutasiStokBarangJadi.jsx"));
const LaporanMutasiStokBahanAlat = React.lazy(()=> import("./views/pages/Dashboard/Laporan/MutasiStok/MutasiStokBahanAlat.jsx"));
const Users = React.lazy(() => import("./views/pages/Dashboard/Users/Users.jsx"));
const Kas = React.lazy(() => import("./views/pages/Dashboard/Kas/Kas.jsx"));
const SuratPembelianBahanAlat = React.lazy(() => import("./views/pages/Dashboard/PengajuanSurat/SuratPembelianBahanAlat.jsx"));
const SuratDanaKasKecil = React.lazy(() => import("./views/pages/Dashboard/PengajuanSurat/SuratDanaKasKecil.jsx"));
const Cutting = React.lazy(()=>import("./views/pages/Dashboard/Cutting/Cutting.jsx"));
const BarangHanca = React.lazy(()=>import("./views/pages/Dashboard/BarangHanca/BarangHanca.jsx"));
const SuratJalanMakloon = React.lazy(()=>import("./views/pages/Dashboard/SuratJalanMakloon/SuratJalanMakloon.jsx"));
const NotaMajun = React.lazy(()=>import("./views/pages/Dashboard/NotaMajun/NotaMajun.jsx"));
const KomponenGaji = React.lazy(()=>import("./views/pages/Dashboard/KomponenGaji/KomponenGaji.jsx"));
const GajiRekanan = React.lazy(()=>import("./views/pages/Dashboard/GajiRekanan/GajiRekanan.jsx"));
const GajiRekanMakloon = React.lazy(()=>import("./views/pages/Dashboard/GajiRekanMakloon/GajiRekanMakloon.jsx"));

// Router
const RouteDashboard = React.lazy(() => import("./routes/RouteDashboard"));

// Layout
const LayoutDashboard = React.lazy(() => import("./views/containers/Dashboard/LayoutDashboard"))

const Login = React.lazy(() => import("./views/pages/Login/Login.jsx"));
const NotFound = React.lazy(() => import("./views/pages/NotFound/NotFound"));


export default function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Homepage />}>
          <Route index element={<Login />} />
          {/* <Route element={<RouteDashboard />}> */}
          <Route element={<LayoutDashboard />}>
            <Route index path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/master/customer" element={<Customer />} />
            <Route path="/dashboard/master/jenis-pembayaran" element={<JenisPembayaran />} />
            <Route path="/dashboard/master/rekanan" element={<Rekanan />} />
            <Route path="/dashboard/master/satuan" element={<Satuan />} />
            <Route path="/dashboard/master/supplier" element={<Supplier />} />
            <Route path="/dashboard/master/bank" element={<Bank />} />
            <Route path="/dashboard/master/barang" element={<Barang />} />
            <Route path="/dashboard/master/barang/ekspor" element={<EksporBarang />} />
            <Route path="/dashboard/master/merk" element={<Merk />} />
            <Route path="/dashboard/master/operator" element={<Operator />} />
            <Route path="/dashboard/master/bahan-alat-produksi" element={<BahanAlatProduksiMaster />} />
            <Route path="/dashboard/master/barang-hanca" element={<BarangHanca />} />
            <Route path="/dashboard/master/jenis-barang" element={<JenisBarang />} />
            <Route path="/dashboard/penjualan/nota-penjualan" element={<NotaPenjualan />} />
            <Route path="/dashboard/penjualan/nota-belum-lunas" element={<NotaBelumLunas />} />
            <Route path="/dashboard/penjualan/surat-jalan" element={<SuratJalan />} />
            <Route path="/dashboard/penjualan/surat-tagihan" element={<SuratTagihan />} />
            <Route path="/dashboard/penjualan/pembayaran-customer" element={<PembayaranCustomer />} />
            <Route path="/dashboard/penjualan/nota-majun" element={<NotaMajun />} />
            <Route path="/dashboard/pembelian/barang-jadi" element={<BarangJadi />} />
            <Route path="/dashboard/pembelian/bahan-alat-produksi" element={<BahanAlatProduksi />} />
            <Route path="/dashboard/pengajuan/pembelian" element={<SuratPembelianBahanAlat />} />
            <Route path="/dashboard/pengajuan/dana-kas-kecil" element={<SuratDanaKasKecil />} />
            <Route path="/dashboard/inventory/hasil-produksi" element={<HasilProduksi />} />
            <Route path="/dashboard/inventory/barang-reject" element={<BarangReject />} />
            <Route path="/dashboard/inventory/pemakaian-bahan-alat" element={<PemakaianBahanAlat />} />
            <Route path="/dashboard/inventory/cutting" element={<Cutting />} />
            <Route path="/dashboard/inventory/surat-jalan-makloon" element={<SuratJalanMakloon />} />
            <Route path="/dashboard/inventory/retur-barang" element={<ReturBarang />} />
            <Route path="/dashboard/inventory/stok-opname" element={<StokOpname />} />
            <Route path="/dashboard/laporan/laporan-penjualan" element={<LaporanPenjualan />} />
            <Route path="/dashboard/laporan/laporan-pembelian-barang-jadi" element={<LaporanPembelianBarangJadi />} />
            <Route path="/dashboard/laporan/laporan-pembelian-bahan-alat" element={<LaporanPembelianBahanAlat />} />
            <Route path="/dashboard/laporan/laporan-piutang" element={<LaporanPiutang />} />
            <Route path="/dashboard/laporan/laporan-hasil-produksi" element={<LaporanHasilProduksi />} />
            <Route path="/dashboard/laporan/laporan-pemakaian-bahan-alat" element={<LaporanPemakaianBahanAlat />} />
            <Route path="/dashboard/laporan/laporan-mutasi-stok-barang-jadi" element={<LaporanMutasiStokBarangJadi />} />
            <Route path="/dashboard/laporan/laporan-mutasi-stok-bahan-alat" element={<LaporanMutasiStokBahanAlat />} />
            <Route path="/dashboard/kas" element={<Kas />} />
            <Route path="/dashboard/setting/users" element={<Users />} />
            <Route path="/dashboard/gaji/komponen" element={<KomponenGaji />} />
            <Route path="/dashboard/gaji/rekanan" element={<GajiRekanan />} />
            <Route path="/dashboard/gaji/rekan-makloon" element={<GajiRekanMakloon />} />
          </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        {/* </Route> */}
      </Routes>
    </Suspense>
  );
}

function Homepage() {
  return (
    <div style={{ width: "100%", overflow: 'hidden' }}>
      <DashboardProvider>
        <Outlet />
      </DashboardProvider>
    </div>
  );
}
